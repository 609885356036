<template>
  <div>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>
            <CIcon name="cil-spreadsheet" />
            <strong> Timezone</strong>
          </CCardHeader>
          <CCardBody>
            <TB :tb_config="tb">
              <e-columns>
                <e-column
                  field="name"
                  headerText="name"
                  type="string"
                ></e-column>
                <e-column
                  field="gmt_offset"
                  headerText="GMT offset"
                  type="number"
                ></e-column>
                <e-column
                  field="dst_offset"
                  headerText="DST offset"
                  type="number"
                ></e-column>
                <e-column
                  field="status"
                  headerText="stato"
                  :dataSource="statusData"
                  foreignKeyValue="name"
                ></e-column>
              </e-columns>
            </TB>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import { DataManager } from "@syncfusion/ej2-data";
import DrfAdaptor from "../../../http/EJ2_adapter";

import TB from "../../../common/TB";
import { apiUrl } from "../../../http";

export default Vue.extend({
  name: "Timezone",
  components: {
    TB,
  },
  data() {
    return {
      tb: {
        data: new DataManager({
          url: apiUrl(`/api/geo/timezone/`),
          adaptor: new DrfAdaptor(),
          crossDomain: true,
        }),
      },
    };
  },
});
</script>
